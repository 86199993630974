import { API_BASE_URL } from 'util/Constants.js';

export async function sendMailToNotifyUserForm(email, centerName) {
  const url = `${API_BASE_URL}/${centerName}/access-form/final-email?email=${email}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const options = {
    method: 'POST',
    headers,
  };

  let response;
  try {
    response = await fetch(url, options);
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }

  if (response.ok) {
    return;
  }

  throw new Error(`Error status: ${response.status}`);
}

export async function checkEmail(email, centerName) {
  const url = `${API_BASE_URL}/${centerName}/access-form/users/email?email=${email}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const options = {
    method: 'POST',
    headers,
  };

  let response;
  try {
    response = await fetch(url, options);
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }

  if (response.ok) {
    return;
  }

  if (response.status === 409) {
    throw new Error('Ja existeix un usuari amb aquest email');
  }
}

export async function checkUsername(username, centerName) {
  const url = `${API_BASE_URL}/${centerName}/access-form/users/username?username=${username}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const options = {
    method: 'POST',
    headers,
  };

  let response;
  try {
    response = await fetch(url, options);
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }

  if (response.ok) {
    return;
  }

  if (response.status) {
    throw new Error('Ja existeix un usuari amb aquest DNI');
  }
}

export async function postCitizen(user, centerName) {
  const url = `${API_BASE_URL}/${centerName}/access-form/users`;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(user),
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMessage = '';
      switch (response.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta 400';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado 404';
          break;
        case 409:
          errorMessage = 'Conflicto 409';
          break;
        default:
          errorMessage = `Error 500: Internal Server Error`;
      }
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }
}

export async function postZoneAuthorizations(zones, timestamp, centerName) {
  const url = `${API_BASE_URL}/${centerName}/access-form/zones?timestamp=${timestamp}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(zones),
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMessage = '';
      switch (response.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta 400';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado 404';
          break;
        case 409:
          errorMessage = 'Conflicto 409';
          break;
        default:
          errorMessage = `Error 500: Internal Server Error`;
      }
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }
}

export async function postWhitelistEntries(licensePlates, timestamp, zoneId, centerName) {
  let zoneIdPath = '';
  if (zoneId !== null) {
    zoneIdPath = `&zone-id=${zoneId}`;
  }
  const url = `${API_BASE_URL}/${centerName}/access-form/whitelist-entries?timestamp=${timestamp}` + zoneIdPath;
  const headers = new Headers({
    'Content-Type': 'application/json',
  });
  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(licensePlates),
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMessage = '';
      switch (response.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta 400';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado 404';
          break;
        case 409:
          errorMessage = 'Conflicto 409';
          break;
        default:
          errorMessage = `Error 500: Internal Server Error`;
      }
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }
}

export async function postFormData(formFields, zoneFile, licensePlateFiles, timestamp) {
  const url = `${API_BASE_URL}/vic/access-form/ftp`;

  var formData = new FormData();
  licensePlateFiles.forEach((file) => {
    formData.append('licensePlateAttachedFiles', file);
  });
  formData.append('zoneAttachedFile', zoneFile);
  formData.append('stringifiedForm', JSON.stringify(formFields));
  formData.append('timestamp', timestamp);

  const options = {
    method: 'POST',
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMessage = '';
      switch (response.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta 400';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado 404';
          break;
        case 409:
          errorMessage = 'Conflicto 409';
          break;
        default:
          errorMessage = `Error 500: Internal Server Error`;
      }
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }
}

export async function postFormDataZones(formFields, zoneFile, timestamp) {
  const url = `${API_BASE_URL}/vic/access-form/ftp/zones`;

  var formData = new FormData();
  formData.append('zoneAttachedFile', zoneFile);
  formData.append('stringifiedForm', JSON.stringify(formFields));
  formData.append('timestamp', timestamp);

  const options = {
    method: 'POST',
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMessage = '';
      switch (response.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta 400';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado 404';
          break;
        case 409:
          errorMessage = 'Conflicto 409';
          break;
        default:
          errorMessage = `Error 500: Internal Server Error`;
      }
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }
}

export async function postFormDataWhitelistEntries(formFields, licensePlateFiles, timestamp, zoneName) {
  const url = `${API_BASE_URL}/vic/access-form/ftp/whitelist-entries`;

  var formData = new FormData();
  licensePlateFiles.forEach((file) => {
    formData.append('licensePlateAttachedFiles', file);
  });
  formData.append('stringifiedForm', JSON.stringify(formFields));
  formData.append('timestamp', timestamp);
  formData.append('zone-name', zoneName);

  const options = {
    method: 'POST',
    body: formData,
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      let errorMessage = '';
      switch (response.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta 400';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado 404';
          break;
        case 409:
          errorMessage = 'Conflicto 409';
          break;
        default:
          errorMessage = `Error 500: Internal Server Error`;
      }
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw new Error('Error de red al intentar comunicarse con el servidor');
  }
}
