import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Row, Col } from 'react-bootstrap';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Select, Spin, Table, Tag, notification, Breadcrumb, Input, Space } from 'antd';
import AccessDenied from 'views/AccessDenied/AccessDenied';

import { getWhitelistEntriesFromUser, getAllZonesFromCenter } from 'util/APIUtils';
import { expireWhitelistEntry, updateWhitelistEntry } from 'repository/whitelistEntries/WhitelistEntryRepository';
import { withTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/es';
import { MdAdd, MdDirectionsCar, MdDownload, MdPeople } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import ButtonLink from 'components/Buttons/ButtonLink';
import colors from 'components/Buttons/colors';
import { TbTrashXFilled } from 'react-icons/tb';

moment.locale('es');

const Validity = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
  PENDING: 'PENDING',
};

class LicensePlates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerName: localStorage.getItem('center'),
      username: this.props.location.state.username,
      userId: this.props.location.state.userId,
      loading: true,

      zones: [],
      data: [],
      pageSize: 10,
    };

    this.columns = [
      {
        title: this.props.t('license-plates.admin.list.table.validity'),
        dataIndex: 'validity',
        key: 'validity',
        align: 'center',
        render: (_, record) => {
          return (
            <Select
              value={record.validity}
              size="large"
              style={{ width: 'auto', borderRadius: '50px' }}
              onChange={(value) => this.updateValidity(record, value)}
            >
              <Select.Option key={Validity.APPROVED}>
                <FaCircle style={{ color: '#28a745' }} /> {this.props.t('license-plates.validity.approved')}
              </Select.Option>
              <Select.Option key={Validity.PENDING}>
                <FaCircle style={{ color: 'orange' }} /> {this.props.t('license-plates.validity.pending')}
              </Select.Option>
              <Select.Option key={Validity.DENIED}>
                <FaCircle style={{ color: 'red' }} /> {this.props.t('license-plates.validity.denied')}
              </Select.Option>
            </Select>
          );
        },
      },
      {
        title: this.props.t('license-plates.admin.list.table.zone'),
        dataIndex: 'zone',
        key: 'zone',
      },
      {
        title: this.props.t('license-plates.admin.list.table.plate'),
        dataIndex: 'licensePlate',
        key: 'licensePlate',
        ...this.getColumnSearchProps('licensePlate'),
      },
      {
        title: this.props.t('license-plates.admin.list.table.status'),
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          const startDate = moment(record.startDate);
          const finishDate = moment(record.finishDate);
          const now = moment();

          if (startDate.isAfter(now) && finishDate.isAfter(startDate)) {
            return <Tag color="orange">{this.props.t('license-plates.status.on-hold')}</Tag>;
          } else if (record.status === 'VALID') {
            return <Tag color="green">{this.props.t('license-plates.status.valid')}</Tag>;
          } else {
            return <Tag color="red">{this.props.t('license-plates.status.expired')}</Tag>;
          }
        },
        filters: [
          {
            text: this.props.t('license-plates.status.valid'),
            value: 'VALID',
          },
          {
            text: this.props.t('license-plates.status.expired'),
            value: 'EXPIRED',
          },
        ],
        onFilter: (value, record) => record.status === value,
      },
      {
        title: this.props.t('license-plates.admin.list.table.created-date'),
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
        defaultSortOrder: 'descend',
        render: (text, record) => {
          if (record.createdDate !== undefined && record.createdDate !== null)
            return moment(record.createdDate).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('license-plates.admin.list.table.start-date'),
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: (a, b) => moment(a.startDate) - moment(b.startDate),
        render: (text, record) => {
          if (record.startDate !== undefined && record.startDate !== null)
            return moment(record.startDate).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('license-plates.admin.list.table.end-date'),
        dataIndex: 'finishDate',
        key: 'finishDate',
        sorter: (a, b) => moment(a.finishDate) - moment(b.finishDate),
        render: (text, record) => {
          if (record.finishDate !== undefined && record.finishDate !== null)
            return moment(record.finishDate).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('license-plates.admin.list.table.reason'),
        dataIndex: 'reason',
        align: 'center',
      },
      {
        title: this.props.t('license-plates.admin.list.table.expire'),
        dataIndex: 'acciones',
        width: '150px',
        align: 'center',
        render: (text, record) => {
          return (
            <ButtonLink
              color="black"
              onClick={() => this.warningWithConfirmAndCancelMessage(record.id)}
            >
              <TbTrashXFilled style={{ fontSize: '30' }} />
            </ButtonLink>
          );
        },
      },
    ];

    const fileColumn = {
      title: 'Fitxer del formulari associat',
      dataIndex: 'attachedFile',
      key: 'attachedFile',
      align: 'center',
      render: (_, record) => {
        const formattedDate = moment(record.createdDate).format('YYYY.MM.DD_HH.mm.ss');

        return (
          <Button
            type="primary"
            style={{ backgroundColor: colors['blue'].main }}
            onClick={() => {
              const username = this.state.username;
              const link = `https://webilla.vic.cat/ftpilla/${username}/${username}_${formattedDate}.zip`;
              window.location.href = link;
            }}
          >
            <MdDownload style={{ fontSize: '25' }} />
          </Button>
        );
      },
    };

    // Add file column to VIC center
    if (localStorage.getItem('center').toUpperCase() === 'VIC') {
      this.columns = [...this.columns, fileColumn];
    }

    this.hideAlert = this.hideAlert.bind(this);
    this.successExpire = this.successExpire.bind(this);
    this.cancelExpire = this.cancelExpire.bind(this);
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    try {
      getAllZonesFromCenter(this.state.centerName)
        .then((zonesResponse) => {
          this.setState({ zones: zonesResponse });
          this.getWhitelistEntriesData(this.state.userId);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      this.props.history.push('/users');
    }
  }

  async updateValidity(whitelistEntry, value) {
    whitelistEntry.validity = value;
    const request = {
      ...whitelistEntry,
      userId: this.state.userId,
      centerName: this.state.centerName,
    };

    await updateWhitelistEntry(request);
    this.updateTable();
    notification['success']({
      message: 'ZinkinData Portal',
      description: this.props.t('license-plates.admin.validity-change-success'),
    });
  }

  getWhitelistEntriesData(userId) {
    getWhitelistEntriesFromUser(userId)
      .then((whitelistEntries) => {
        const result = whitelistEntries.map((whitelistEntry) => {
          return {
            id: whitelistEntry.id,
            zone: this.getZoneName(whitelistEntry.zoneId),
            licensePlate: whitelistEntry.licensePlate,
            validity: whitelistEntry.validity,
            status: whitelistEntry.status,
            createdDate: whitelistEntry.createdDate,
            startDate: whitelistEntry.startDate,
            finishDate: whitelistEntry.finishDate,
            reason: whitelistEntry.reason,
          };
        });

        this.setState({ data: result, loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  getZoneName(zoneId) {
    let zoneName = '';

    this.state.zones.forEach((zone) => {
      if (zone.id === zoneId) {
        zoneName = zone.name;
      }
    });

    return zoneName;
  }

  updateTable() {
    this.setState({ loading: true });
    this.getWhitelistEntriesData(this.state.userId);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonPrimary
            color="blue"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <SearchOutlined /> {this.props.t('license-plates.admin.search-button')}
          </ButtonPrimary>
          <ButtonPrimary
            color="black"
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('license-plates.admin.reset-button')}
          </ButtonPrimary>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => text,
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  warningWithConfirmAndCancelMessage(whitelistEntryId) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('license-plates.admin.list.expire-modal.title')}
          onConfirm={() => this.successExpire(whitelistEntryId)}
          onCancel={() => this.cancelExpire()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('license-plates.admin.list.expire-modal.confirm-button')}
          cancelBtnText={this.props.t('license-plates.admin.list.expire-modal.cancel-button')}
          showCancel
        />
      ),
    });
  }

  successExpire(whitelistEntryId) {
    expireWhitelistEntry(whitelistEntryId)
      .then(() => {
        this.hideAlert();
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('license-plates.admin.expire-success'),
        });

        this.updateTable();
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: this.props.t('license-plates.admin.expire-error'),
        });
      });
  }

  cancelExpire() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('license-plates.admin.list.cancel-modal.title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('license-plates.admin.list.cancel-modal.message')}
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  render() {
    const { currentUser, history } = this.props;

    if (!currentUser.isAdmin() && !currentUser.isSuperAdmin()) {
      return <AccessDenied />;
    }

    const antIcon = (
      <LoadingOutlined
        style={{ fontSize: 24 }}
        spin
      />
    );
    const setSizePage = (current, pageSize) => this.setState({ pageSize });

    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Spin
                spinning={this.state.loading}
                indicator={antIcon}
              >
                <Breadcrumb
                  separator=">"
                  style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
                >
                  <Breadcrumb.Item
                    className="menu-history"
                    onClick={() => history.push('/users')}
                  >
                    <div style={{ display: 'inline-block', height: '30px' }}>
                      <i
                        style={{
                          lineHeight: '30px',
                          verticalAlign: 'middle',
                          marginRight: '5px',
                          fontSize: '30px',
                        }}
                      >
                        <MdPeople />
                      </i>
                      {this.props.t('sidebar.citizen')}
                    </div>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className="menu-history"
                    onClick={() => history.push('/users')}
                  >
                    <div style={{ display: 'inline-block', height: '30px' }}>
                      <i
                        style={{
                          lineHeight: '30px',
                          verticalAlign: 'middle',
                          marginRight: '5px',
                          fontSize: '30px',
                        }}
                      >
                        <MdDirectionsCar />
                      </i>
                      {this.props.t('license-plates.admin.title')}
                    </div>
                  </Breadcrumb.Item>
                </Breadcrumb>

                <Card
                  content={
                    <div>
                      <legend>
                        {this.props.t('license-plates.admin.list.table.title')} {this.state.username}
                      </legend>
                      <ButtonPrimary
                        type="success"
                        shape="round"
                        size="large"
                        style={{
                          margin: '10px 0 20px 0',
                        }}
                        onClick={() =>
                          history.push({
                            pathname: '/admin-license-plates-add',
                            state: { username: this.state.username, userId: this.state.userId },
                          })
                        }
                      >
                        {this.props.t('license-plates.admin.add-button')}
                        <MdAdd
                          style={{
                            verticalAlign: 'sub',
                            fontSize: '20',
                            marginLeft: '5px',
                          }}
                        />
                      </ButtonPrimary>

                      <Table
                        dataSource={this.state.data}
                        columns={this.columns}
                        pagination={{
                          pageSize: this.state.pageSize,
                          showSizeChanger: true,
                          onShowSizeChange: setSizePage,
                        }}
                      />
                    </div>
                  }
                />
              </Spin>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withTranslation('common')(LicensePlates);
