import React, { useEffect, useState } from 'react';

import {
  Select,
  Radio,
  notification,
  Spin,
  Breadcrumb,
  Card,
  Form,
  Input,
  Tooltip,
  ConfigProvider,
  DatePicker,
  Modal,
} from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MdDirectionsCar, MdPeople } from 'react-icons/md';

import esES from 'antd/locale/es_ES';
import caES from 'antd/locale/ca_ES';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'dayjs/locale/es';
import 'dayjs/locale/ca';
import dayjs from 'dayjs';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Buttons/ButtonPrimary.jsx';

import { getAllZonesIlla, getZoneAuthorizationsByUsername } from 'util/APIUtils';
import { postWhitelistEntryAdmin } from 'repository/whitelistEntries/WhitelistEntryRepository';
import colors from 'components/Buttons/colors';

const Option = Select.Option;
const { RangePicker } = DatePicker;

const AdminLicensePlatesCreate = ({ currentUser, history, location, t }) => {
  const [zarZones, setZarZones] = useState([]);
  const [typeSelected, setTypeSelected] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(fetchZones, []);

  function fetchZones(username = location.state.username) {
    getZoneAuthorizationsByUsername(username).then((zoneAuthrozationsResponse) => {
      if (zoneAuthrozationsResponse.length === 0) {
        Modal.warning({
          title: t('license-plates.admin.create.no-zones-modal.title'),
          content: t('license-plates.admin.create.no-zones-modal.message'),
          onOk: () => history.push('/users'),
          okText: t('license-plates.admin.create.no-zones-modal.confirm-button'),
          centered: true,
        });
      }
      getAllZonesIlla(localStorage.getItem('center'))
        .then((zonesIllaResponse) => {
          let zoneAuthorizations = zoneAuthrozationsResponse.map((zoneAuthorization) => {
            let zoneName = '';

            zonesIllaResponse.forEach((zone) => {
              if (zone.id === zoneAuthorization.zoneId) zoneName = zone.name;
            });

            return {
              zoneName,
              ...zoneAuthorization,
            };
          });

          setZarZones(zoneAuthorizations);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  const zarZonesOptions = zarZones.map((zoneAuthorization) => {
    return <Option key={zoneAuthorization.zoneId}>{zoneAuthorization.zoneName}</Option>;
  });

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const response = await postWhitelistEntryAdmin(
        location.state.username,
        localStorage.getItem('center'),
        values.type,
        values.plate,
        startDate,
        finishDate,
        values.zone,
        ''
      );

      if (response.status === 201) {
        notification.success({
          message: 'ZinkinData Portal',
          description: t('license-plates.admin.create.success'),
        });

        history.push({
          pathname: '/admin-license-plates',
          state: { username: location.state.username, userId: location.state.userId },
        });
      } else if (response.status === 409) {
        notification.error({
          message: 'ZinkinData Portal',
          duration: 5,
          description: t('license-plates.admin.create.already-exist'),
        });
      } else if (response.status === 429) {
        notification.error({
          message: 'ZinkinData Portal',
          duration: 5,
          description: t('license-plates.admin.create.too-many-requests'),
        });
      } else if (response.status === 422) {
        let body = await response.text();
        if (body.includes('ON HOLD')) {
          notification.error({
            message: 'ZinkinData Portal',
            duration: 5,
            description: t('license-plates.admin.create.max-on-hold-entries'),
          });
        } else {
          notification.error({
            message: 'ZinkinData Portal',
            duration: 5,
            description: t('license-plates.admin.create.date-out-of-range'),
          });
        }
      }
    } catch (error) {
      notification.error({
        message: 'LPR Connect',
        description: t('license-plates.admin.create.error'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <Breadcrumb
        separator=">"
        style={{ marginBottom: '10px', fontSize: 20, lineHeight: '50px' }}
      >
        <Breadcrumb.Item
          className="menu-history"
          onClick={() => history.push('/users')}
        >
          <div style={{ display: 'inline-block', height: '30px' }}>
            <i
              style={{
                lineHeight: '30px',
                verticalAlign: 'middle',
                marginRight: '5px',
                fontSize: '30px',
              }}
            >
              <MdPeople />
            </i>
            {t('sidebar.citizen')}
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className="menu-history"
          onClick={() => history.push('/users')}
        >
          <div style={{ display: 'inline-block', height: '30px' }}>
            <i
              style={{
                lineHeight: '30px',
                verticalAlign: 'middle',
                marginRight: '5px',
                fontSize: '30px',
              }}
            >
              <MdDirectionsCar />
            </i>
            {t('license-plates.admin.title')}
          </div>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="menu-history">{t('license-plates.admin.create.title')}</Breadcrumb.Item>
      </Breadcrumb>
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24 }}
            spin
          />
        }
      >
        <Card title={t('license-plates.admin.create.title')}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            size="large"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              label={t('license-plates.admin.create.form.zone')}
              name="zone"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.zone'),
                },
              ]}
            >
              <Select
                mode="multiple"
                value={zarZones}
                size="large"
                placeholder={t('license-plates.admin.create.form.zone-placeholder')}
              >
                {zarZonesOptions}
              </Select>
            </Form.Item>
            <Form.Item
              label={t('license-plates.admin.create.form.plate')}
              name="plate"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.plate'),
                },
                {
                  pattern: new RegExp(/^[A-Z0-9]+$/),
                  message: t('license-plates.admin.create.form.plate-placeholder'),
                },
              ]}
            >
              <Input
                placeholder="Ex: 1234ABC"
                suffix={
                  <Tooltip
                    title={t('license-plates.admin.create.form.plate-placeholder')}
                    placement="right"
                    color={colors.blue.main}
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item
              label={t('license-plates.admin.create.form.time')}
              name="type"
              rules={[
                {
                  required: true,
                  message: t('license-plates.admin.create.form.plate'),
                },
              ]}
            >
              <Radio.Group
                size="large"
                optionType="button"
                buttonStyle="solid"
                value={typeSelected}
                onChange={(event) => {
                  setTypeSelected(event.target.value);
                  setStartDate(null);
                  setFinishDate(null);
                }}
              >
                <Tooltip
                  placement="bottom"
                  title={t('license-plates.admin.create.form.day-tooltip')}
                  color={colors.blue.main}
                >
                  <Radio value={'DAILY'}>{t('license-plates.admin.create.form.day')}</Radio>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={t('license-plates.admin.create.form.permanent-time-tooltip')}
                  color={colors.blue.main}
                >
                  <Radio
                    disabled={currentUser.isBasicUser()}
                    value={'PERMANENT'}
                  >
                    {t('license-plates.admin.create.form.permanent-time')}
                  </Radio>
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={t('license-plates.admin.create.form.custom-time-tooltip')}
                  color={colors.blue.main}
                >
                  <Radio
                    disabled={currentUser.isBasicUser()}
                    value={'CUSTOM'}
                  >
                    {t('license-plates.admin.create.form.custom-time')}
                  </Radio>
                </Tooltip>
              </Radio.Group>
            </Form.Item>
            {typeSelected !== 'PERMANENT' && typeSelected !== '' ? (
              <ConfigProvider locale={currentUser.language === 'ca' ? caES : esES}>
                <Form.Item
                  label={t('license-plates.admin.create.form.calendar-placeholder')}
                  name="dates"
                  rules={[
                    {
                      required: true,
                      message: 'És obligatori',
                    },
                  ]}
                >
                  {typeSelected === 'DAILY' ? (
                    <DatePicker
                      placeholder={t('license-plates.admin.create.form.calendar-placeholder-1')}
                      style={{ width: '100%' }}
                      size="large"
                      value={startDate === null ? null : dayjs(startDate)}
                      disabledDate={(current) => {
                        const marginDays = localStorage.getItem('center').toUpperCase() === 'TORELLO' ? 6 : 6;
                        return current.isBefore(moment().subtract(marginDays, 'day'));
                      }}
                      onChange={(event) => {
                        setStartDate(event.$d);
                        setFinishDate(null);
                      }}
                      format={'DD/MM/YYYY'}
                    />
                  ) : (
                    <RangePicker
                      style={{ width: '100%' }}
                      size="large"
                      value={[
                        startDate === null ? null : dayjs(startDate),
                        finishDate === null ? null : dayjs(finishDate),
                      ]}
                      disabledDate={(current) => {
                        const marginDays = localStorage.getItem('center').toUpperCase() === 'TORELLO' ? 6 : 6;
                        return current.isBefore(moment().subtract(marginDays, 'day'));
                      }}
                      onChange={(event) => {
                        setStartDate(event[0].$d);
                        setFinishDate(event[1].$d);
                      }}
                      placeholder={[
                        t('license-plates.admin.create.form.start-date-placeholder'),
                        t('license-plates.admin.create.form.end-date-placeholder'),
                      ]}
                      format={'DD/MM/YYYY'}
                    />
                  )}
                </Form.Item>
              </ConfigProvider>
            ) : (
              <></>
            )}
            <Form.Item
              label=" "
              colon={false}
            >
              <ButtonPrimary
                color="blue"
                shape="round"
                size="large"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                {t('license-plates.admin.add-button')}
              </ButtonPrimary>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    </div>
  );
};

export default withTranslation('common')(AdminLicensePlatesCreate);
