import moment from 'moment';
import { API_BASE_URL, buildOptionsWithAuth } from 'util/Constants.js';

export async function postWhitelistEntryAdmin(
  username,
  centerName,
  type,
  licensePlate,
  startDate,
  finishDate,
  zoneIds,
  reason
) {
  const headers = buildOptionsWithAuth('POST');
  const payload = {
    centerName,
    plate: licensePlate,
    type,
    startDate: startDate ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
    finishDate: finishDate ? moment(finishDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null,
    zoneIds,
    reason,
  };

  const url = `${API_BASE_URL}/admin/whitelist-entries/${username}`;
  const response = await fetch(url, { ...headers, body: JSON.stringify(payload) });

  return response;
}

export async function postWhitelistEntryCitizen(licensePlate, startDate, endDate, zoneId, reason) {
  const headers = buildOptionsWithAuth('POST');
  var url =
    API_BASE_URL +
    '/whitelist-entries' +
    '?license-plate=' +
    licensePlate +
    '&type=DAILY' +
    '&start-date=' +
    startDate +
    '&zone-id=' +
    zoneId +
    '&center-name=' +
    localStorage.getItem('center');

  if (endDate !== undefined) {
    url += '&finish-date=' + endDate;
  }

  if (reason !== '') {
    url += '&reason=' + reason;
  }

  return fetch(url, headers).then((response) => {
    return response;
  });
}

export function expireWhitelistEntry(whitelistEntryId) {
  const headers = buildOptionsWithAuth('PUT');
  const url = `${API_BASE_URL}/whitelist-entries/${whitelistEntryId}/expire?center-name=${localStorage.getItem(
    'center'
  )}`;

  return fetch(url, headers).then((response) => {
    if (response.status !== 204) throw new Error('Error intern de servidor');
  });
}

export function updateWhitelistEntry({
  id,
  licensePlate,
  startDate,
  finishDate,
  reason,
  status,
  validity,
  userId,
  centerName,
}) {
  const headers = buildOptionsWithAuth('PUT');
  const url = `${API_BASE_URL}/admin/${centerName}/whitelist-entries`;
  const requestBody = { id, licensePlate, startDate, finishDate, status, validity, reason, userId };

  return fetch(url, { ...headers, body: JSON.stringify(requestBody) }).then((response) => {
    if (response.status !== 204) throw new Error('Error intern de servidor');
  });
}
