import React, { Component } from 'react';
import Card from 'components/Card.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Grid, Row, Col } from 'react-bootstrap';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Tag, notification, Breadcrumb, Input, Space, Tooltip, Flex } from 'antd';
import {
  getAllWhiteListFromUser,
  getAllZonesFromCenter,
  getAllZonesIlla,
  getZoneAuthorizationsByUser,
} from 'util/APIUtils';
import { expireWhitelistEntry } from 'repository/whitelistEntries/WhitelistEntryRepository';
import { MdAdd, MdDirectionsCar } from 'react-icons/md';
import { FaCircle } from 'react-icons/fa';
import moment from 'moment';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';

import Button from 'antd-button-color';

import AddEmail from 'components/AddEmail';
import ButtonPrimary from 'components/Buttons/ButtonPrimary';
import { TbTrashXFilled } from 'react-icons/tb';
import colors from 'components/Buttons/colors';

moment.locale('es');

class LicensePlates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      zones: [],
      zoneAuthorizations: [],
      authorizedZones: [],
      data: [],
    };

    this.columns = [
      {
        title: this.props.t('license-plates.citizen.list.table.validity'),
        dataIndex: 'validity',
        key: 'validity',
        align: 'center',
        render: (_, record) => {
          const validityTags = {
            APPROVED: (
              <Tooltip
                placement="top"
                title={this.props.t('license-plates.validity.approved')}
                color={colors.blue.main}
              >
                <FaCircle style={{ color: '#28a745' }} />
              </Tooltip>
            ),
            PENDING: (
              <Tooltip
                placement="top"
                title={this.props.t('license-plates.validity.pending')}
                color={colors.blue.main}
              >
                <FaCircle style={{ color: 'orange' }} />
              </Tooltip>
            ),
            DENIED: (
              <Tooltip
                placement="top"
                title={this.props.t('license-plates.validity.denied')}
                color={colors.blue.main}
              >
                <FaCircle style={{ color: 'red' }} />
              </Tooltip>
            ),
          };

          return validityTags[record.validity] || <div></div>;
        },
      },
      {
        title: this.props.t('license-plates.citizen.list.table.zone'),
        dataIndex: 'zone',
        key: 'zone',
      },
      {
        title: this.props.t('license-plates.citizen.list.table.plate'),
        dataIndex: 'licensePlate',
        key: 'licensePlate',
        ...this.getColumnSearchProps('licensePlate'),
      },
      {
        title: this.props.t('license-plates.citizen.list.table.status'),
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          const startDate = moment(record.startDate);
          const endDate = moment(record.endDate);
          const now = moment();

          if (startDate.isAfter(now) && endDate.isAfter(startDate)) {
            return <Tag color="orange">{this.props.t('license-plates.status.on-hold')}</Tag>;
          } else if (record.status === 'VALID') {
            return <Tag color="green">{this.props.t('license-plates.status.valid')}</Tag>;
          } else {
            return <Tag color="red">{this.props.t('license-plates.status.expired')}</Tag>;
          }
        },
        filters: [
          {
            text: this.props.t('license-plates.status.valid'),
            value: 'VALID',
          },
          {
            text: this.props.t('license-plates.status.expired'),
            value: 'EXPIRED',
          },
        ],
        onFilter: (value, record) => record.status === value,
      },
      {
        title: this.props.t('license-plates.citizen.list.table.created-date'),
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: (a, b) => moment(a.createdDate) - moment(b.createdDate),
        defaultSortOrder: 'descend',
        render: (text, record) => {
          if (record.createdDate !== undefined && record.createdDate !== null)
            return moment(record.createdDate).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('license-plates.citizen.list.table.start-date'),
        dataIndex: 'startDate',
        key: 'startDate',
        render: (text, record) => {
          if (record.startDate !== undefined && record.startDate !== null)
            return moment(record.startDate).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('license-plates.citizen.list.table.end-date'),
        dataIndex: 'endDate',
        key: 'endDate',
        render: (text, record) => {
          if (record.endDate !== undefined && record.endDate !== null)
            return moment(record.endDate).format('DD-MM-YYYY HH:mm:ss');
        },
      },
      {
        title: this.props.t('license-plates.citizen.list.table.reason'),
        dataIndex: 'reason',
        align: 'center',
      },
    ];

    this.expireColumn = {
      title: this.props.t('license-plates.citizen.list.table.expire'),
      dataIndex: 'acciones',
      width: '150px',
      render: (text, record) => {
        if (record.endDate !== undefined && record.endDate !== null && record.status === 'VALID')
          return (
            <Button
              onClick={() => {
                this.warningWithConfirmAndCancelMessage(record.id);
              }}
              type={'primary'}
              danger
              with="link"
              width="50%"
              style={{ fontSize: '38' }}
            >
              <TbTrashXFilled style={{ fontSize: '30' }} />
            </Button>
          );
        else return <div></div>;
      },
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.successExpire = this.successExpire.bind(this);
    this.cancelExpire = this.cancelExpire.bind(this);
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(this);
    this.zoneAuthorizationsHaveAnyTrimestralAccess = this.userHasNonTrimestralAccess.bind(this);
  }

  async componentWillMount() {
    try {
      const zoneAuthorizations = await getZoneAuthorizationsByUser();
      const allZones = await getAllZonesIlla(localStorage.getItem('center'));

      const authorizedZones = zoneAuthorizations.map((zoneAuthorization) => {
        const zone = allZones.find((zone) => zone.id === zoneAuthorization.zoneId);
        return {
          id: zoneAuthorization.zoneId,
          name: zone.name,
          reason: zoneAuthorization.reason,
        };
      });

      this.setState({
        zoneAuthorizations,
        authorizedZones,
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    getAllZonesFromCenter(localStorage.getItem('center'))
      .then((response) => {
        this.setState({ zones: response });
        this.updateTable();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateTable() {
    this.setState({ loading: true });

    getAllWhiteListFromUser()
      .then((whiteListEntriesResponse) => {
        var resultList = whiteListEntriesResponse.map((whiteListEntry) => {
          return {
            key: whiteListEntry.id,
            id: whiteListEntry.id,
            zone: this.getZoneName(whiteListEntry.zoneId),
            licensePlate: whiteListEntry.licensePlate,
            status: whiteListEntry.status,
            validity: whiteListEntry.validity,
            createdDate: whiteListEntry.createdDate,
            startDate: whiteListEntry.startDate,
            endDate: whiteListEntry.finishDate,
            reason: whiteListEntry.reason,
          };
        });

        this.setState({ data: resultList, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  getZoneName(zoneId) {
    let zoneName = '';
    this.state.zones.forEach((zone) => {
      if (parseInt(zone.id) === parseInt(zoneId)) zoneName = zone.name;
    });

    return zoneName;
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <ButtonPrimary
            color="blue"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            <SearchOutlined /> {this.props.t('license-plates.citizen.search-button')}
          </ButtonPrimary>
          <ButtonPrimary
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            color="black"
            style={{ width: 90 }}
          >
            {this.props.t('license-plates.citizen.reset-button')}
          </ButtonPrimary>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => (this.state.searchedColumn === dataIndex ? text : text),
  });

  userHasNonTrimestralAccess() {
    const expectedReason = 'Necessito un accés causal (accés màxim de 3 mesos)';

    if (!Array.isArray(this.state.zoneAuthorizations)) {
      return false;
    }

    return this.state.zoneAuthorizations.some((zoneAuthorization) => zoneAuthorization.reason !== expectedReason);
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  warningWithConfirmAndCancelMessage(whitelistEntryId) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={this.props.t('license-plates.citizen.list.expire-modal.title')}
          onConfirm={() => this.successExpire(whitelistEntryId)}
          onCancel={() => this.cancelExpire()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
          confirmBtnText={this.props.t('license-plates.citizen.list.expire-modal.confirm-button')}
          cancelBtnText={this.props.t('license-plates.citizen.list.expire-modal.cancel-button')}
          showCancel
        />
      ),
    });
  }

  expireWhitelistEntryRequest(whitelistEntryId) {
    this.setState({ loading: true });
    expireWhitelistEntry(whitelistEntryId)
      .then(() => {
        this.hideAlert();
        notification['success']({
          message: 'ZinkinData Portal',
          description: this.props.t('license-plates.citizen.expire-success'),
        });

        this.updateTable();
        this.setState({ loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: 'ZinkinData Portal',
          description: this.props.t('license-plates.citizen.expire-error'),
        });
        this.setState({ loading: false });
      });
  }

  successExpire(whitelistEntryId) {
    this.expireWhitelistEntryRequest(whitelistEntryId);
  }

  cancelExpire() {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: 'block' }}
          title={this.props.t('license-plates.citizen.list.cancel-modal.title')}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {this.props.t('license-plates.citizen.list.cancel-modal.message')}
        </SweetAlert>
      ),
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  render() {
    if (
      this.props.currentUser.role !== 'ROLE_ADMIN' &&
      this.props.currentUser.role !== 'ROLE_SUPERADMIN' &&
      this.props.currentUser.role !== 'ROLE_USER'
    ) {
      return (
        <div>
          <h1 style={{ fontWeight: 'bold', paddingLeft: '50px' }}>ACCES DENIED</h1>
        </div>
      );
    } else {
      const antIcon = (
        <LoadingOutlined
          style={{ fontSize: 24 }}
          spin
        />
      );

      return (
        <div className="main-content">
          {this.state.alert}
          <Grid fluid>
            {this.props.currentUser.email === '' ||
            this.props.currentUser.email === null ||
            this.props.currentUser.email === undefined ? (
              <AddEmail {...this.props} />
            ) : null}

            <Breadcrumb
              separator=">"
              style={{
                marginBottom: '10px',
                marginTop: '10px',
                fontSize: 20,
                lineHeight: '50px',
              }}
            >
              <Breadcrumb.Item>
                <div style={{ display: 'inline-block', height: '30px' }}>
                  <i
                    style={{
                      lineHeight: '30px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                      fontSize: '30px',
                    }}
                  >
                    <MdDirectionsCar />
                  </i>
                  {this.props.t('sidebar.license-plate')}
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col md={12}>
                <Spin
                  spinning={this.state.loading}
                  indicator={antIcon}
                >
                  <Card
                    content={
                      <div>
                        <Flex
                          gap="small"
                          style={{ marginBottom: '20px' }}
                        >
                          {this.userHasNonTrimestralAccess() && (
                            <ButtonPrimary
                              color="blue"
                              shape="round"
                              size="large"
                              onClick={() => this.props.history.push('/license-plates-add')}
                            >
                              {this.props.t('license-plates.citizen.add-button')}
                              <MdAdd
                                style={{
                                  verticalAlign: 'sub',
                                  fontSize: '20',
                                  marginLeft: '5px',
                                }}
                              />
                            </ButtonPrimary>
                          )}
                          {localStorage.getItem('center').toUpperCase() === 'VIC' && (
                            <ButtonPrimary
                              color="blue"
                              shape="round"
                              size="large"
                              onClick={() =>
                                this.props.history.push({
                                  pathname: '/formulari-acces',
                                  state: {
                                    initialStep: 2,
                                    singlePage: true,
                                    username: this.props.currentUser.username,
                                    authorizedZones: this.state.authorizedZones,
                                  },
                                })
                              }
                            >
                              Sol·licitar nova matrícula
                              <MdAdd
                                style={{
                                  verticalAlign: 'sub',
                                  fontSize: '20',
                                  marginLeft: '5px',
                                }}
                              />
                            </ButtonPrimary>
                          )}
                        </Flex>

                        {localStorage.getItem('center').toUpperCase() === 'TORELLO' ? (
                          <Table
                            dataSource={this.state.data}
                            columns={[
                              ...this.columns,
                              {
                                title: this.props.t('license-plates.citizen.list.table.reason'),
                                dataIndex: 'reason',
                                key: 'reason',
                              },
                              this.expireColumn,
                            ]}
                            pagination={{
                              pageSize: 10,
                              showSizeChanger: false,
                            }}
                          />
                        ) : (
                          <Table
                            dataSource={this.state.data}
                            columns={[...this.columns, this.expireColumn]}
                            pagination={{
                              pageSize: 10,
                              showSizeChanger: false,
                            }}
                          />
                        )}
                      </div>
                    }
                  />
                </Spin>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
  }
}

export default withTranslation('common')(LicensePlates);
